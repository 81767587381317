/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SvgGithub from '../SVG/social/github';
import SvgLinkedin from '../SVG/social/linkedin';
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';

export const SocialMenu = () => {

    const icon = {
        hover: {
            scale: 1.2,
            color: "#C7D2FE",
            transition: {
                type: 'spring',
                damping: 4,
                stiffness: 60,
                mass: 0.4,
                duration: 0.25,
            },
        },
        tap: {
            scale: 1.2,
            transition: {
                type: 'spring',
                damping: 4,
                stiffness: 60,
                mass: 0.4,
                duration: 0.25,
            },
        }
    }

    return (
        <>
            <motion.div className="fixed flex bottom-0 w-24 items-center justify-end flex-col gap-2 z-10 pb-20 h-full gradient-darkblue">
                <LangSelector />
                <motion.a whileHover="hover" variants={icon} aria-label="Github" name="github" href="https://github.com/Killian360" target="_blank" rel="noopener noreferrer" className="border-transparent inline-block p-1 text-indigo-900">
                    <SvgGithub className="fill-current h-8 w-auto" />
                </motion.a>
                <motion.a whileHover="hover" variants={icon} aria-label="Linkedin" name="linkedin" href="https://www.linkedin.com/in/jeremy-charras-4373a720/" target="_blank" rel="noopener noreferrer" className="border-transparent inline-block p-1 text-indigo-900">
                    <SvgLinkedin className="fill-current h-8 w-auto" />
                </motion.a>
            </motion.div>
        </>
    )
}

const LangSelector = () => {

    const context = useContext(I18nextContext);
    const { languages, originalPath } = useI18next();
    const [selectLanguage, setSelectLanguage] = useState();

    useEffect(() => {
        setSelectLanguage(context.language);

    },[context.language]);
    

    return (
        <ul className="flex flex-col gap-3 items-center uppercase pb-4 right-0 font-DINCONBLACK z-110">
            {languages.map((lng) => (
                <li key={lng} className={`transform font-bold text-base transition-all duration-300  ${selectLanguage === lng ? "opacity-100 pointer-events-none text-indigo-200" : "opacity-50 pointer-events-auto text-indigo-800"} hover:opacity-75`}>
                    <Link to={originalPath} language={lng}>
                        {lng}
                    </Link>
                </li>
            ))}
        </ul>
    )
}