import React from 'react'
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';
import ReportViewer from 'react-lighthouse-viewer';
import jsonReport from './report.json';

const AuditMenu = ({ AuditIsOpen }) => {

  const dispatch = useDispatch();

  const menuAnim = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 }
    },
    enter: {
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: {
      opacity: 0,
      transition: { when: "afterChildren" }
    }
  }
  const bgmAnim = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 }
    },
    enter: {
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.5 }
    }
  }


  const formAnim = {
    initial: {
      opacity: 0,
      scale: 0,
      rotate: 0,
      borderRadius: "100rem",
      transition: { duration: 0.5 }
    },
    enter: {
      opacity: 1,
      x: 0,
      rotate: 270,
      borderRadius: "0.7rem",
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        duration: 0.65,
        stiffness: 100,
        damping: 5,
        mass: 0.2,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.7,
      y: 0,
      x: 0,
      rotate: 0,
      transition: {
        type: "tween",
        duration: 0.25
      },
    }
  }

  function clickout() {
    dispatch({ type: 'AuditIsOpen', value: false });
  }

  return (
    <>
      <AnimatePresence>
        {AuditIsOpen === true &&
        <>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={menuAnim}
            className="flex  justify-center p-10 items-center w-screen h-screen fixed z-40"
          >
            <motion.div
              className="flex relative justify-center pb-10 w-1/2 h-screen-3/4 overflow-y-scroll text-sm scrolling-auto items-center bg-gray-100 z-40 shadow-lg"
              variants={formAnim}
            >
              <div className="absolute mt-12 h-full w-full overflow-y-auto"><ReportViewer json={jsonReport} /></div>
                  <div className="lh-sticky-header"></div>
            </motion.div>
            <div className="absolute top-0 w-screen h-screen" onClick={() => clickout()}></div>
          </motion.div>
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={menuAnim}
            className="w-full h-full fixed z-30 backdrop-blur">
            </motion.div>
          </>
      }
      </AnimatePresence>
    </>
  )
}

export default connect(state => ({
  AuditIsOpen: state.AuditMenuReducer.isOpen,
}), null)(AuditMenu);