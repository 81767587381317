/* eslint-disable object-curly-spacing */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
const initialState = {
    isActivate: false,
};

export default function WebGlState(state = initialState, action) {
    switch (action.type) {
        case 'WebglState':
            return state = {isActivate: action.value};
        default:
            return state;
    }
}
