/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { motion, AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';

import Header from '../header/header';

import TopBtn from '../UI/TopButton';
import WebGLHome from '../home/webgl/webgl_bg';
import { SocialMenu } from '../UI/socialMenu/socialMenu';
import ContactMenu from '../contact';
import AuditMenu from '../audit';
import { useDispatch } from 'react-redux';
import { Bglines } from '../UI/bglines/bglines';

const timing = 0.5;

const variants = {
  initial: {
    opacity: 0,
    y: 0,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: timing,
      delay: timing,
      when: 'beforeChildren',
      type: 'spring',
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.2,
      when: 'afterChildren',
    },
  },
};

// eslint-disable-next-line react/prop-types
const Layout = ({ location, children, webglState }) => {

  const dispatch = useDispatch();

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `);

  useEffect(() => {
    dispatch({ type: 'WebglState', value: true });
  }, [])


  return (
    <>
      <SocialMenu />
      <ContactMenu />
      <AuditMenu />
      <Header siteTitle={data.site.siteMetadata.title} />
      <AnimatePresence exitBeforeEnter>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
          <Bglines />
        </motion.main>
      </AnimatePresence>
      {webglState === true && <WebGLHome />}
      <TopBtn />
    </>
  );
};

export default connect(state => ({
  formIsOpen: state.FormMenuReducer.isOpen,
  webglState: state.WebGlState.isActivate,
}), null)(Layout);
