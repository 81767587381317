/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';
import WebGlState from '../components/home/webgl/reducer';
import SliderHomeReducer from '../components/home/Prez/reducer';
import FormMenuReducer from './reducer/form';
import AuditMenuReducer from './reducer/audit';
import ProjectHeader from './reducer/projectheader';

const rootReducer = combineReducers({ WebGlState, SliderHomeReducer, FormMenuReducer, AuditMenuReducer, ProjectHeader });

// preloadedState will be passed in by the plugin
export default rootReducer;
