const initialState = {
    isOpen: false,
};

export default function AuditMenuReducer(state = initialState, action) {
    switch (action.type) {
        case 'AuditIsOpen':
            return state = {isOpen: action.value};
        default:
            return state;
    }
}