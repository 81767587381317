/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
import React from "react";
import isNode from 'detect-node'
import { TweenMax } from "gsap";
import "./style.scss";
import Particles from 'react-particles-js';


import displacementIMG from "../../../assets/webgl/displacement.jpg";
import cloudIMG from "../../../assets/webgl/cloud.png";

// const mapDispatchToProps = dispatch => {
//   return {
//     tooglewebglon : () => dispatch({
//       type : 'TOOGLEWEBGLON'
//     })
//   }
// }

let PIXI

if (!isNode) {
  PIXI = require('pixi.js')
}

class WebGLHome extends React.Component {

constructor(props) {
  super(props);
  this.state = {
  };
  this.killWebgl = this.killWebgl.bind(this);
  this.CreateWebgl = this.CreateWebgl.bind(this);
  this.Destroy = this.Destroy.bind(this);
  this.state = { Renderer: null, AppID: null };
}

componentDidMount() {
  this.CreateWebgl();
}

componentWillUnmount() {
  if (this.state.AppID !== null) {
    var appTarget = this.state.AppID;
    var appRenderer = this.state.Renderer;
    appTarget.destroy();
    appRenderer.destroy();
    this.setState({ Renderer: null, AppID: null });
  }
}

killWebgl() {
  if (this.state.AppID !== null) {
    const canvas = document.getElementById("home-maincanvas");
    TweenMax.to(canvas, 0.5, { opacity: 0, onComplete: this.Destroy });
  }
}

Destroy() {
  var appTarget = this.state.AppID;
  var appRenderer = this.state.Renderer;
  var canvasWrapper = document.getElementById("home-maincanvas");
  if (canvasWrapper) {
    canvasWrapper.removeChild(canvasWrapper.firstChild);
  }
  appTarget.destroy();
  appRenderer.destroy();
  this.setState({ Renderer: null, AppID: null });
}

// componentDidUpdate(prevProps) {
// const appTarget = this.state.AppID;
// (this.props.HOMESLIDE.slide !==0 && this.props.WEB_GL === true) ||
// (prevProps.Navpanel !== this.props.Navpanel) &&
// (this.props.Navpanel === true && this.props.WEB_GL ===
// true && this.props.HOMESLIDE.slide === 0)
// ? appTarget.stop() :  appTarget.start();
// }


CreateWebgl() {
  const canvas = document.getElementById("home-maincanvas");
  const Window = (typeof window !== 'undefined') && window || '';
  // TweenMax.set(canvas, {opacity:0});
  // if (store.getState().WEB_GL === false) {
  // } else {

  var rendererOptions = {
    antialiasing: false,
    transparent: true,
    resolution: Window.devicePixelRatio,
    autoResize: true,
  };
  TweenMax.to(canvas, 0.5, { opacity: 0.6 });

  const renderer = PIXI.autoDetectRenderer(1920, 1080, rendererOptions);
  const app = new PIXI.Application(1920, 1080, rendererOptions);
  const displacementSprite = new PIXI.Sprite.fromImage(displacementIMG);
  const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
  const Divcontainer = document.getElementById("home-maincanvas");
  const container = new PIXI.Container();
  const ratio = Math.min(Window.innerWidth / 800, Window.innerHeight / 600);

  const cloud = [];
  let p = 0;
  const Color = ["0x3b0aab", "0x24c4d2", "0x710bef", "0x610bef", "0x610bef", "0x0bc7ef"];

  this.setState({ Renderer: renderer, AppID: app });

  Divcontainer.appendChild(app.view);

  app.view.style.width = '100vw';
  app.view.style.height = '100vh';

  app.stage.addChild(container);

  displacementSprite.anchor.set(0.5, 0.5);

  app.stage.addChild(displacementSprite);
  container.filters = [displacementFilter];

  displacementFilter.scale.x = 100 * ratio;
  displacementFilter.scale.y = 100 * ratio;

  displacementSprite.width = 1920;
  displacementSprite.height = 1080;

  displacementSprite.x = app.renderer.width / 2;
  displacementSprite.y = app.renderer.height / 2;

  // create an array to store all the sprites

  for (var i = 0; i < 15; i += 1) {
    cloud[i] = PIXI.Sprite.fromImage(cloudIMG);
    cloud[i].tint = Color[p];
    p += 1;

    if (p > Color.length) {
      p = 0;
    }

    cloud[i].alpha = Math.floor(Math.random() * 0.1) + 0.6;
    cloud[i].anchor.set(0.5);
    cloud[i].scale.set(2 + Math.random() * 0.2);

    // scatter them all
    cloud[i].x = Math.random() * app.renderer.width;
    cloud[i].y = Math.random() * app.renderer.height;

    // create a random direction in radians
    cloud[i].direction = Math.random() * Math.PI * 0.5;

    // this number will be used to modify the direction of the sprite over time
    cloud[i].turningSpeed = Math.random() - 0.01;

    // create a random speed between 0 - 2, and these maggots are slooww
    cloud[i].speed = 0;

    cloud[i].offset = Math.random() * 100;

    container.addChild(cloud[i]);
  }

  app.ticker.add(() => {
    for (var p = 0; p < cloud.length; p++) {
      var cloudToAnimate = cloud[p];
      cloudToAnimate.direction += cloudToAnimate.turningSpeed * 0.01;
      cloudToAnimate.x += Math.sin(cloudToAnimate.direction) * (cloudToAnimate.speed * cloudToAnimate.scale.y);
      cloudToAnimate.y += Math.cos(cloudToAnimate.direction) * (cloudToAnimate.speed * cloudToAnimate.scale.y);
      cloudToAnimate.rotation = -cloudToAnimate.direction + 0.5;
    }
  });
}

render() {
  return (
    <>
      <div className="particles">
        <Particles
          params={{
            "particles": {
              "number": {
                "value": 40,
                "density": {
                  "enable": true,
                  "value_area": 600
                }
              },
              "color": {
                "value": "#ffffff"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0,
                  "color": "#000000"
                },
                "polygon": {
                  "nb_sides": 5
                }
              },
              "opacity": {
                "value": 1,
                "random": true,
                "anim": {
                  "enable": true,
                  "speed": 0.6,
                  "opacity_min": 0,
                  "sync": false
                }
              },
              "size": {
                "value": 1,
                "random": true,
                "anim": {
                  "enable": true,
                  "speed": 0,
                  "size_min": 0.3,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": false,
                "distance": 150,
                "color": "#ffffff",
                "opacity": 0.4,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 0.3,
                "direction": "none",
                "random": true,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 600
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": false,
                  "mode": "repulse"
                },
                "onclick": {
                  "enable": false,
                  "mode": "repulse"
                },
                "resize": false
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 250,
                  "size": 10,
                  "duration": 2,
                  "opacity": 0,
                  "speed": 3
                },
                "repulse": {
                  "distance": 400,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            }
          }}
        />
      </div>
      <div className="canvas_gradient" />
      <div id="home-maincanvas" />
    </>
  );
}
}

export default WebGLHome;
