/* eslint-disable object-curly-spacing */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
const initialState = {
    slideNbr: 1,
};

export default function SliderHomeReducer(state = initialState, action) {
    switch (action.type) {
        case 'SetSliderIndexation':
            return state = { slideNbr: action.index};
        case 'IncrementIndex':
            return state = { slideNbr: state.slideNbr + 1};
        case 'DecrementIndex':
             return state = {slideNbr: state.slideNbr - 1};
        default:
            return state;
    }
}
