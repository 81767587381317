import React, { useContext } from 'react';
import { motion } from "framer-motion"

export const Bglines = () => (
    <motion.div variants={lineWrapper} initial="initial" animate="animate" className="line_wrapper">
        <motion.div variants={lineReverse} className="one"></motion.div>
        <motion.div variants={lineReverse} className="two"></motion.div>
        <motion.div variants={lineReverse} className="three"></motion.div>
        <motion.div variants={lineReverse} className="four"></motion.div>
        <motion.div variants={lineReverse} className="five"></motion.div>
        <motion.div variants={lineReverse} className="six"></motion.div>
        <motion.div variants={lineReverse} className="sept"></motion.div>
        <motion.div variants={lineReverse} className="eight"></motion.div>
    </motion.div>
)

const lineWrapper = {
    initial: { x: 0 },
    animate: {
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.15,
        },
    },
}

const line = {
    initial: { y: "-100%" },
    animate: {
        y: 0,
        transition: {
            type: "spring",
            duration: 1.5
        },
    },
}

const lineReverse = {
    initial: { y: "100%" },
    animate: {
        y: 0,
        transition: {
            type: "tween",
            ease: [0.17, 0.67, 0.83, 0.67],
        },
    },
}