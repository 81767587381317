import React from 'react';
import './header.scss';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { ButtonSmall } from '../UI/buttons/small';
const bgvariants = {
  hover: { scale: 1.2 },
  show: { y: -200 },
  hidden: { y: 200 },
};

const Header = () => {


  // const controls = useAnimation();
  const { scrollY } = useViewportScroll();
  // scrollY < 150 ? controls.start({ y: -150}) : controls.start({ y: 0});
  const translatey = useTransform(scrollY, [0, 100], [200, 90], {
    clamp: true,
  });

  const opacity = useTransform(scrollY, [0, 450], [0, 1], {
    clamp: true,
  });

  return (
    <>
      <motion.header
        className="fixed w-full h-28 z-50 flex flex-row justify-center items-center pointer-events-none"
      >
        <div className="text-white whitespace-no-wrap text-lg font-radiance flex flex-row justify-between w-full px-24">
          <div className="flex flex-row items-center gap-3">
            <Link to="/" className="pointer-events-auto">
              <p>Creative Nebula</p>
            </Link>
            <div className="flex items-center justify-center select-none tracking-normal font-poppins-medium py-1 px-2 text-xs rounded-full bg-white text-purple-900"><b>WIP</b></div>
          </div>
          <motion.div animate="enter">
            <ButtonSmall icon="projects" href="/projects" className="w-32 h-14 text-white cursor-pointer pointer-events-auto"><Trans>Projects</Trans></ButtonSmall>
          </motion.div>
        </div>
        <motion.div
          className="bg_color"
          variants={bgvariants}
          style={{ opacity }}
          className="absolute"
        />
      </motion.header>
    </>
  );
};

export default Header;
