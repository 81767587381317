const initialState = {
    isVisible: true,
};

export default function ProjectHeader(state = initialState, action) {
    switch (action.type) {
        case 'SetVisible':
            return state = {isVisible: action.value};
        default:
            return state;
    }
}