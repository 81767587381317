import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from 'framer-motion';
import { ButtonForm } from '../UI/buttons/form';
import { useDispatch } from 'react-redux';
import "./style.css"
import { Icon } from '@iconify/react';
import bxCodeAlt from '@iconify/icons-bx/bx-code-alt';
import webGridAlt from '@iconify/icons-uil/web-grid-alt';
import fileInvoiceDollarSolid from '@iconify/icons-la/file-invoice-dollar-solid';
import questionIcon from '@iconify/icons-bi/question';
import network4 from '@iconify/icons-carbon/network-4';
import bxsDownArrow from '@iconify/icons-bx/bxs-down-arrow';
import bxsUpArrow from '@iconify/icons-bx/bxs-up-arrow';
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactForm = () => {

    const dispatch = useDispatch();

    const [state, setState] = React.useState({});
    const [validate, setValidate] = React.useState(false);
    const [Display, setDisplay] = React.useState(false);
    const [LabelSelect, setLabelSelect] = React.useState("Select");

    const { t } = useTranslation(['form']);

    const handleChange = e => setState({ ...state, [e.target.name]: e.target.value })

    // CSS styling Tailwind
    const LabelStyle = "px-10 font-poppins-medium w-full flex flex-col items-center justify-center"
    const TitleStyle = " w-full font-bold text-md font-radiance text-purple-800 tracking-normal mb-5"
    const InputStyle = " p-2 px-4 shadow-sm label-bg font-radiance border-0 rounded-md text-2xl md:text-lg text-purple-700 transition ease-out duration-700 w-full"
    const ErrorMessage = "w-full text-right py-2 tracking-normal text-red-600 font-poppins-medium text-xs h-5"
    const InputMessageStyle = "px-5 p-2 px-4 outline-none label-bg rounded-md font-radiance resize-none text-lg text-purple-700 w-full h-32"
    const SubmitButtonStyle = "mb-8 focus:outline-none outline-none border-grey border-b-4 rounded-full text-xs  text-white uppercase font-Gilroy_extrabold tracking-widest py-2 px-8 bg-btn-green"

    const { register, errors, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault()
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                // 'form-name': form.getAttribute('name'),
                'form-name': 'contact',
                ...state,
            }),
        }).then(
            dispatch({ type: 'FormIsOpen', value: false })
        )

    }

    function clickout(Display, Label) {
        setDisplay(!Display);
        setLabelSelect(Label === undefined ? "Select" : Label);
    }



    const errorAnim = {
        initial: {
            opacity: 0,
            x: -10
        },
        enter: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
        exit: {
            opacity: 0,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
    };

    const selectAnim = {
        initial: {
            opacity: 0,
            height: 0,
            y: -10
        },
        enter: {
            opacity: 1,
            y: 0,
            height: "10rem",
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 17,
                mass: 1.2,
                stiffness: 150,
            },
        },
        exit: {
            opacity: 0,
            height: 0,
            y: -10,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
    };

    const formAnim = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
    };

    return (
        <>
            <section className="w-full relative flex items-center justify-center flex-col">
                {validate &&
                    <motion.div animate={{ opacity: 1 }} className="opacity-0 absolute w-full h-full">
                        Merci !
        </motion.div>
                }
                <form
                    name="contact"
                    className="w-full"
                    method="POST"
                    data-netlify="true"
                    onSubmit={handleSubmit(onSubmit)}
                    action="/"
                    netlify-honeypot="bot-field"
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <p className="hidden">
                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <AnimatePresence>
                        {validate === false &&
                            <motion.div
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                variants={formAnim}
                                className="flex items-center justify-center flex-col"
                            >
                                <div style={{ backgroundColor: "rgb(45 14 107)" }} className="flex flex-col items-center justify-center relative text-center overflow-hidden rounded-t-lg w-full py-5 font-bold text-4xl font-radiance text-white tracking-normal mb-10">
                                    <motion.div className="absolute z-10 overlayAnimate top-0  pointer-events-none w-full h-full"></motion.div>
                                    <div className="flex items-center justify-center flex-row w-full"><div className="flex justify-center flex-row items-center w-11/12 h-16"><Icon icon={network4} className="w-24 h-auto fill-current p-6" /><p className="uppercase tracking-widest leading-none text-left pr-24">{t('form~Space mail')}</p></div></div>
                                    <p className="w-full mt-2 font-normal text-base">{t('form~Send a nice message to the stars')}</p>
                                </div>
                                <motion.label className={LabelStyle}>
                                    <p className={TitleStyle}>{t('form~Name')}</p>
                                    <motion.input className={InputStyle} type="text" name="nameInput" onChange={handleChange} ref={register({ required: true })} />
                                    <AnimatePresence>
                                        <div className="w-full h-5">
                                            {errors.nameInput &&
                                                <motion.p
                                                    initial="initial"
                                                    animate="enter"
                                                    exit="exit"
                                                    variants={errorAnim}
                                                    className={ErrorMessage} >
                                                    {t('form~✘ Field required')}
                                                </motion.p>
                                            }
                                        </div>
                                    </AnimatePresence>
                                </motion.label>
                                <label className={LabelStyle}>
                                    <p className={TitleStyle}>{t('form~Email')}</p>
                                    <input className={InputStyle} type="email" name="email" onChange={handleChange} ref={register({ required: true })} />
                                    <AnimatePresence>
                                        <div className="w-full h-5">
                                            {errors.email &&
                                                <motion.p
                                                    initial="initial"
                                                    animate="enter"
                                                    exit="exit"
                                                    variants={errorAnim}
                                                    className={ErrorMessage} >
                                                    {t('form~✘ Field required')}
                                                </motion.p>
                                            }
                                        </div>
                                    </AnimatePresence>
                                </label>
                                <label className={LabelStyle}>
                                    <p className={TitleStyle}>{t('form~Subject')}</p>
                                    <div id="select-box" className="mb-5 relative label-bg cursor-pointer select-none w-full h-10 font-radiance font-bold tracking-wide custom-select focus:outline-none p-2 py-4 rounded-lg text-purple-700 shadow-sm text-left">
                                        <div id="arrow" className="absolute right-0 top-0 px-4 h-10 py-2">{Display === false ? <Icon icon={bxsDownArrow} className="fab h-full text-green-600 fill-current" /> : <Icon icon={bxsUpArrow} className="fab h-full fill-current" />}</div>
                                        <div id="select-button" onClick={() => clickout(Display)} className=" cursor-pointer px-2 flex items-center justify-right absolute w-full h-full">
                                            <div id="selected-value" className="pointer-events-none">
                                                <input className="s-c top" type="radio" name="platform" value={LabelSelect} />
                                                <span>{LabelSelect}</span>
                                            </div>
                                            <div id="chevrons">
                                                <i className="fas fa-chevron-up"></i>
                                                <i className="fas fa-chevron-down"></i>
                                            </div>
                                            <input type="checkbox" name="Subject" value={LabelSelect} onChange={handleChange} className="w-full custom-select focus:outline-none p-2 rounded-lg text-purple-700 shadow-sm text-left" id="options-view-button" />
                                        </div>
                                        <AnimatePresence>
                                            {Display === true &&
                                                <motion.div
                                                    id="options"
                                                    className="font-normal origin-top overflow-hidden origin shadow-lg"
                                                    initial="initial"
                                                    animate="enter"
                                                    exit="exit"
                                                    variants={selectAnim}
                                                >
                                                    <div className="option h-10 flex justify-left items-center" onClick={() => clickout(Display, t('form~Design'))}>
                                                        <input className="s-c top" type="radio" name="platform" value="Design" />
                                                        <input className="s-c bottom" type="radio" name="platform" value="Design" />
                                                        <span className="cursor-pointer label flex flex-row gap-2 items-center justify-left "><Icon icon={webGridAlt} className="fab h-auto w-5 fill-current" />{t('form~Design')}</span>
                                                    </div>
                                                    <div className="option h-10 flex justify-left items-center" onClick={() => clickout(Display, t('form~Developement'))}>
                                                        <input className="s-c top" type="radio" name="platform" value="Developement" />
                                                        <input className="s-c bottom" type="radio" name="platform" value="Developement" />
                                                        <span className="label flex flex-row gap-2 items-center justify-left "><Icon icon={bxCodeAlt} className="fab h-auto w-5 fill-current" />{t('form~Developement')}</span>
                                                    </div>
                                                    <div className="option h-10 flex justify-left items-center" onClick={() => clickout(Display, t('form~Questions'))}>
                                                        <input className="s-c top" type="radio" name="platform" value="Questions" />
                                                        <input className="s-c bottom" type="radio" name="platform" value="Questions" />
                                                        <span className="label flex flex-row gap-2 items-center justify-left "><Icon icon={questionIcon} className="fab h-auto w-5 fill-current" />{t('form~Questions')}</span>
                                                    </div>
                                                    <div className="option h-10 flex justify-left items-center" onClick={() => clickout(Display, t('form~Quotation'))}>
                                                        <input className="s-c top" type="radio" name="platform" value="Quotation" />
                                                        <input className="s-c bottom" type="radio" name="platform" value="Quotation" />
                                                        <span className="label flex flex-row gap-2 items-center justify-left "><Icon icon={fileInvoiceDollarSolid} className="fab h-auto w-5 fill-current" />{t('form~Quotation')}</span>
                                                    </div>

                                                    <div id="option-bg"></div>
                                                </motion.div>
                                            }
                                        </AnimatePresence>
                                    </div>
                                    {/* <select name="Subject" className="custom-select w-full custom-select focus:outline-none p-2 rounded-lg text-purple-700 shadow-sm text-left" id="subject" ref={register({ required: true })}>
                                    <option value="Design">Design</option>
                                    <option value="Dev.">Developement</option>
                                    <option value="Both">Design & developement</option>
                                </select> */}
                                </label>
                                <label className={LabelStyle}>
                                    <p className={TitleStyle}>{t('form~Message')}</p>
                                    <textarea className={InputMessageStyle} name="message" onChange={handleChange} maxLength="350" ref={register({ required: true })}></textarea>
                                    <AnimatePresence>
                                        <div className="w-full h-5">
                                            {errors.message &&
                                                <motion.p
                                                    initial="initial"
                                                    animate="enter"
                                                    exit="exit"
                                                    variants={errorAnim}
                                                    className={ErrorMessage} >
                                                    {t('form~✘ Field required')}
                                                </motion.p>
                                            }
                                        </div>
                                    </AnimatePresence>
                                </label>
                                <ButtonForm icon="send" color="pink" >{t('form~Send')}</ButtonForm>
                            </motion.div>
                        }
                    </AnimatePresence>
                </form>
            </section>
        </>
    )
}

export default ContactForm