
import React from 'react';
import Layout from './src/components/layouts';

import './src/style/main.scss';

export { default as wrapRootElement } from './src/redux/ReduxWrapper';


// export const wrapPageElement = ({ element, props }) => <i18NextProvider><Layout {...props}>{element}</Layout></i18NextProvider>;

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

const transitionDelay = 500;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    );
  }
  return false;
};
