import React from 'react';
import './button.scss';
import { motion, useViewportScroll, useTransform } from 'framer-motion';

const variants = {
  hover: { scale: 1.2 },
  show: { y: -150 },
  hidden: { y: 150 },
};

const iconVariants = {
  hover: {
    y: [0, -100, 100, 0],
    opacity: [1, 0, 0, 1],
    scale: [1, 1, 1, 1.2],
  },
};

const TopBtn = () => {
  // const controls = useAnimation();
  const { scrollY } = useViewportScroll();
  // scrollY < 150 ? controls.start({ y: -150}) : controls.start({ y: 0});
  const translatey = useTransform(scrollY, [0, 450], [0, -150], {
    clamp: true,
  });

  function handleClickTop() {
    window.scrollTo({
  top: 0,
  behavior: 'smooth',
});
}

    return (
      <>
<motion.div
  whileHover="hover"
  whileTap={{ scale: 1 }}
  className="TopButton"
  style={{ y: translatey }}
  // animate={controls}
  onClick={() => handleClickTop()}
  transition={{ type: 'spring', duration: 0.1 }}
  variants={variants}
>
<svg id="Top_arrow" x="0px" y="0px" viewBox="0 0 31.49 31.49">
<motion.path
fill="#FFF"
variants={iconVariants}
transition={{ type: 'spring', duration: 0.45 }}
d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047
v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0
c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
/>
</svg>
</motion.div>
      </>
    );
};

export default TopBtn;
